<template>
  <div>
    <renyiHeader :menu-id="2" />
    <div class="introContent">
      <div class="introTitle">
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">组织机构</span>
      </div>
      <div class="orgTreeBox">
        <vue2-org-tree
          :data="data"
          :renderContent="renderContent"
          @on-node-click="clickNode"
        />
      </div>
      <div class="richTextBox">
        <div class="infoTitle"><span class="dot"></span>{{ orgInfoTitle }}</div>
        <div v-html="orgInfo.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrgTreeList, getArticle } from "@/service/renyiOverview";
import renyiHeader from "./components/renyiHeader.vue";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    renyiHeader,
  },
  data() {
    return {
      selectedNodeId: null,
      node: null,
      data: [],
      query: {
        level1: "org",
        level2: "",
      },
      orgInfo: [],
      orgInfoTitle: "",
    };
  },
  created() {
    this.getOrgTree();
  },
  methods: {
    TreeDataSource(arr) {
      if (!Array.isArray(arr)) {
        return false;
      }
      return arr.map((v, i) => {
        return {
          label: v.name,
          value: v.id,
          ...v,
          children: v.children ? this.TreeDataSource(v.children) : [],
        };
      });
    },
    async getOrgTree() {
      const res = await getOrgTreeList();
      this.data = this.TreeDataSource(res.data)[0] || {};
      this.orgInfoTitle = this.data.children[0].name || "";
      this.query.level2 = this.data.children[0].id;
      getArticle(this.query).then((res) => {
        this.orgInfo = res.data || {};
        this.orgInfo.body = formatRichText(this.orgInfo.body) || "";
      });
    },
    renderContent(h, data) {
      if (data.children.length) {
        return <div class="t1">{data.label}</div>;
      } else {
        return <div class="t2">{data.label}</div>;
      }
    },
    clickNode(e, value) {
      const nodeList = document.querySelectorAll(".t2");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].classList.remove("t3");
      }
      if (value.children.length == 0) {
        e.target.classList.add("t3");
      }
      this.orgInfoTitle = value.name || "";
      this.query.level2 = value.id;
      getArticle(this.query).then((res) => {
        this.orgInfo = res.data || {};
        this.orgInfo.body = formatRichText(this.orgInfo.body) || "";
      });
    },
  },
};
</script>

<style lang="less">
.introContent {
  margin-top: 0.3467rem;
  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .richTextBox {
    min-height: 12rem;
    margin-bottom: 0.6667rem;
  }
}
.orgTreeBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.infoTitle {
  margin-left: 0.4rem;
  font-size: 0.4533rem;
  font-weight: 800;
  color: #212122;
}
.t1 {
  width: 4.1333rem;
  height: 0.8rem;
  color: #fff;
  font-size: 0.2667rem;
  background: url("../../../assets/img/renyiOverview/node_title.png") no-repeat;
  background-size: 100%;
  text-align: center;
  line-height: 0.8rem;
  cursor: pointer;
}
.t2 {
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;
  width: 0.6667rem;
  height: 2.5333rem;
  font-size: 0.2667rem;
  background: url("../../../assets/img/renyiOverview/node.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.t2:hover {
  background: url("../../../assets/img/renyiOverview/node_active.png") no-repeat;
  background-size: 100%;
}

.t3 {
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;
  width: 0.6667rem;
  height: 2.5333rem;
  background: url("../../../assets/img/renyiOverview/node_active.png") no-repeat;
  background-size: 100%;
}
.org-tree-node-label .org-tree-node-label-inner {
  padding: 0;
}
</style>
