<template>
  <div>
    <div>
      <img
        class="topImg"
        src="../../../assets/img/renyiOverview/banner.png"
        alt=""
      />
    </div>
    <!-- <renyiHeader /> -->
    <router-view></router-view>
    <PageFooter />
  </div>
</template>

<script>
import renyiHeader from "./components/renyiHeader.vue";
export default {
  components: {
    renyiHeader,
  },
};
</script>

<style lang="less" scoped>
.topImg {
  width: 10rem;
  height: 2.3467rem;
  object-fit: cover;
}
</style>
