<template>
  <div>
    <renyiHeader :menu-id="7" />
    <div class="introContent">
      <div class="introTitle">
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">北京国际戏剧中心</span>
      </div>
      <div class="richTextBox">
        <div v-html="richText1"></div>
        <div id="shoudu">
          <div class="shoudu2">曹禺剧场</div>
          <div v-html="richText2"></div>
        </div>
        <div id="beijingrenyi">
          <div class="shoudu2">人艺小剧场</div>
          <div v-html="richText3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticle } from "@/service/renyiOverview";
import renyiHeader from "./components/renyiHeader.vue";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    renyiHeader,
  },
  data() {
    return {
      form1: {
        level1: "SUMMARY",
        level2: "beijin",
      },
      form2: {
        level1: "SUMMARY",
        level2: "caoyu",
      },
      form3: {
        level1: "SUMMARY",
        level2: "renyi",
      },
      richText1: "",
      richText2: "",
      richText3: "",
    };
  },
  created() {
    this.getIntroContent();
  },
  mounted() {
    document.getElementById(`shoudu`).scrollIntoView();
  },
  methods: {
    async getIntroContent() {
      const params = [this.form1, this.form2, this.form3];
      const promises = params.map((params) => {
        return getArticle(params);
      });
      Promise.all(promises).then((res) => {
        this.richText1 = formatRichText(res[0].data.body);
        this.richText2 = formatRichText(res[1].data.body);
        this.richText3 = formatRichText(res[2].data.body);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introContent {
  margin-top: 0.3467rem;
  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .richTextBox {
    min-height: 12rem;
    margin-bottom: 0.6667rem;
  }
  #shoudu,
  #beijingrenyi {
    margin-top: 0.2667rem;
  }
  .shoudu2 {
    font-size: 0.4533rem;
    font-weight: 800;
    color: #212122;
    margin-left: 0.4rem;
  }
}
</style>
