/*
 * @Author: zhongxq
 * @Date: 2023-10-22 19:52:39
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-25 14:26:38
 * @FilePath: \beijing-peoples-art-museum\src\service\survey.js
 * @Description:
 *
 */
import service from "./index";

// 获取详情
export const getDetail = () => {
  return service({
    url: `/open/portal/introInfo/page`,
    method: "get",
  });
};
// 获取院领导详情
export const getLeaderDetail = () => {
  return service({
    url: `/open/portal/leaderInfo/page`,
    method: "get",
  });
};
