<template>
  <div class="introTitle">
    <div class="title">
      <img src="../../../../assets/img/renyiOverview/jiantou.png" />
      <span class="titlecont">{{ title }}</span>
    </div>
    <div class="heng"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="less" scoped>
.introTitle {
  .title {
    display: flex;
    align-items: center;
    img {
      width: 0.2667rem;
      height: 0.5333rem;
      margin-right: 0.1733rem;
    }
    .titlecont {
      font-size: 0.48rem;
      font-weight: 700;
      color: #212121;
    }
  }
  .heng {
    width: 0.3467rem;
    height: 0.0533rem;
    background: #f9670c;
    margin: 0.5733rem 0.1733rem;
  }
}
</style>
