<template>
  <div>
    <div class="imageGroup clearfix">
      <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
        <swiper-slide class="bannerImg" v-for="item in imgList" :key="item.id">
          <img :src="item.image" class="img" @error="imgOnError($event)" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div> -->
      </swiper>
      <div class="imgName">{{ imgName }}</div>
    </div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  name: "imageGroup",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    height: {
      type: String,
      default: "1.3333rem",
    },
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imgName: this.imgList[0].name,
      currentIndex: 0,
      swiperOptionTop: {
        spaceBetween: 0,
        autoplay: {
          //自动轮播
          delay: 5000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转
        },
        on: {
          slideChange: () => {
            this.currentIndex = this.$refs.swiperTop.swiper.realIndex;
            this.imgName = this.imgList[this.currentIndex].name;
          },
        },
      },
      isMounted: false,
    };
  },
  computed: {
    swiperobj1() {
      if (!this.isMounted) return { index: 0 };
      return this.$refs.swiperTop.swiper;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      // swiperTop.controller.control = swiperThumbs
      // swiperThumbs.controller.control = swiperTop
      swiperTop.thumbs.swiper = swiperThumbs;
      this.isMounted = true;
    });
  },
  methods: {
    change(type) {
      let activeIndex = this.swiperobj1.activeIndex;
      if (type === "prev" && activeIndex !== 0) {
        this.swiperobj1.slideTo(activeIndex - 1, 100);
      }
      if (type === "next" && activeIndex !== this.imgList.length) {
        this.swiperobj1.slideTo(activeIndex + 1, 100);
      }
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>
<style lang="less" scoped>
.imageGroup,
.swiperBox,
.imgBox {
  height: 4.8rem;
  margin-top: 0.8133rem;
  margin-bottom: 1.3333rem;
}
.imageGroup {
  position: relative;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 5.0667rem !important;
  width: 100%;
}
.thumbsBox {
  padding: 0 0.2667rem;
  height: 20% !important;
  position: relative;
  top: -2.4rem;
  left: 0;
}
.gallery-thumbs {
  height: 100% !important;
  box-sizing: border-box;
  padding: 0.0133rem 0;
  display: flex;
  justify-content: center;
}
.gallery-thumbs .swiper-slide {
  height: 100%;
  border: 0.0133rem solid #f9680d;
  box-sizing: border-box;
}
// .gallery-thumbs .swiper-slide-active {
//   border: 1px solid #2D9AE9;
// }
.prevBtn,
.nextBtn {
  color: #666;
  position: absolute;
  font-size: 0.4rem;
  font-weight: bold;
  top: calc(50% - 15px);
}
.prevBtn {
  left: -0.04rem;
}
.nextBtn {
  right: -0.04rem;
}
.btnDisabled {
  color: #ccc;
  cursor: not-allowed;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bannerImg {
  position: relative;
}
.viewIntro {
  position: absolute;
  top: 0.2667rem;
  left: 0.2667rem;
  z-index: 99;
  font-size: 0.5rem;
  color: #fff;
  .introBox {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.1333rem;
    }
  }
}
.imgName {
  font-size: 0.32rem;
  margin-top: 0.3067rem;
  text-align: center;
  color: #777777;
}
::v-deep .swiper-wrapper {
  width: auto;
}
::v-deep .swiper-button-prev {
  width: 1.0667rem;
  height: 1.0667rem;
  background-size: 100%;
  background-image: url("../../../../assets/img/renyiOverview/left.png");
  transform: translateY(-50%);
}
::v-deep .swiper-button-next {
  width: 1.0667rem;
  height: 1.0667rem;
  background-size: 100%;
  background-image: url("../../../../assets/img/renyiOverview/right.png");
  transform: translateY(-50%);
}
::v-deep .el-dialog {
  width: 80%;
}
::v-deep .el-dialog__header {
  padding: 0.2667rem 0.2667rem 0 0.2667rem;
}
::v-deep .el-dialog__title {
  line-height: 100%;
  font-size: 0.5rem;
  box-sizing: border-box;
}
::v-deep .el-icon-close {
  font-size: 0.5rem;
}
::v-deep .el-dialog__body {
  font-size: 0.4rem;
  text-align: justify;
  padding: 0.2667rem 0.2667rem;
  box-sizing: border-box;
}
::v-deep .swiper-pagination-bullet {
  width: 0.1867rem;
  height: 0.1867rem;
}
::v-deep .swiper-pagination-bullet-active {
  background-color: #f9680d;
}
::v-deep .swiper-pagination-bullet {
  // opacity: 0.8;
  // background-color: #d2d2d2;
}
</style>
