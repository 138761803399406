<template>
  <div class="container">
    <div class="heng"></div>
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  width: 8.9333rem;
  background: #f7f7fa;
  padding: 0.64rem 0.44rem;
  margin-top: 1.2rem;
  box-sizing: border-box;
  .heng {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0.5333rem;
    height: 0.0267rem;
    background: #f9670c;
  }
}
</style>
