<template>
  <div>
    <renyiHeader :menu-id="6" />
    <div class="introContent">
      <div class="introTitle">
        <img src="../../../assets/img/renyiOverview/jiantou.png" />
        <span class="title">北京人艺艺术创作中心</span>
      </div>
      <div class="richTextBox">
        <div v-html="richText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticle } from "@/service/renyiOverview";
import renyiHeader from "./components/renyiHeader.vue";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    renyiHeader,
  },
  data() {
    return {
      form: {
        level1: "SUMMARY",
        level2: "BJRYYSCZZX",
      },
      richText: "",
    };
  },
  created() {
    this.getIntroContent();
  },
  methods: {
    async getIntroContent() {
      const params = { ...this.form };
      const res = await getArticle(params);
      this.richText = formatRichText(res.data.body);
    },
  },
};
</script>

<style lang="less" scoped>
.introContent {
  margin-top: 0.3467rem;
  .introTitle {
    display: flex;
    align-items: center;
    padding-left: 0.4rem;
    box-sizing: border-box;
    img {
      width: 0.24rem;
      height: 0.4533rem;
      margin-right: 0.2133rem;
    }
    .title {
      font-size: 0.4533rem;
      font-weight: 800;
      color: #212122;
    }
  }
  .richTextBox {
    min-height: 12rem;
    margin-bottom: 0.6667rem;
  }
}
</style>
