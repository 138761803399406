<template>
  <div class="mobileHeader">
    <div class="headerbox">
      <div
        class="header-item"
        v-for="item in headerList"
        :key="item.id"
        :class="menuId == item.id ? 'active' : ''"
        @click="onChlick(item)"
        :id="`menu${item.id}`"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      headerList: [
        {
          name: "首都剧场",
          id: 3,
          path: "/renyiOverView/capitalTheatre",
        },
        {
          name: "北京人艺实验剧场",
          id: 6,
          path: "/renyiOverView/shiyanjuchang",
        },
        {
          name: "北京国际戏剧中心",
          id: 4,
          path: "/renyiOverView/theatreCenter",
        },
        {
          name: "曹禺剧场",
          id: 7,
          path: "/renyiOverView/caoyu",
        },
        {
          name: "人艺小剧场",
          id: 8,
          path: "/renyiOverView/xiaojuchang",
        },
        {
          name: "菊隐剧场",
          id: 5,
          path: "/renyiOverView/juYinTheatre",
        },
        // {
        //   name: "北京人艺艺术创作中心",
        //   id: 6,
        //   path: "/renyiOverView/createCenter",
        // },
      ],
      // index: 1,
    };
  },
  created() {},
  mounted() {
    if (this.menuId == 5 || this.menuId == 7 || this.menuId == 8) {
      document.getElementById(`menu${this.menuId}`).scrollIntoView();
    }
  },
  methods: {
    onChlick(item) {
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="less" scoped>
.mobileHeader {
  .headerbox {
    display: flex;
    align-items: center;
    height: 1.0667rem;
    box-sizing: border-box;
    overflow-x: scroll;
    white-space: nowrap;
    padding-left: 0.4rem;
    border-bottom: 1px solid #e4e6eb;
    .header-item {
      font-size: 0.4rem;
      margin-right: 0.6667rem;
    }
  }
}
.active {
  font-weight: 800;
  color: #f9680d;
}
</style>
