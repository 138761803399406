<template>
  <div class="imgItem">
    <img
      :src="img"
      :style="{ width: width + 'px', height: height + 'px' }"
      alt=""
    />
    <span class="imgName">《{{ imgName }}》</span>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    width: Number,
    height: Number,
    imgName: String,
  },
};
</script>

<style lang="less" scoped>
.imgItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  .imgName {
    font-size: 14px;
    color: #777777;
  }
}
</style>
