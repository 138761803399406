<template>
  <div class="Box" :style="{ height: height === 'auto' ? 'auto' : '620px' }">
    <div class="introTitle">
      <img src="../../../../assets/img/renyiOverview/jiantou.png" />
      <span class="title">{{ title }}</span>
    </div>
    <div class="line"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    backgroundImg: String,
    height: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.Box {
  width: 100%;
  margin-top: 97px;
  padding: 46px 0 39px 77px;
  box-sizing: border-box;
  .introTitle {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 40px;
      margin-right: 14px;
    }
    .title {
      font-size: 30px;
      font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
      font-weight: 700;
      color: #212122;
      letter-spacing: 0.75px;
    }
  }
  .line {
    width: 26px;
    height: 4px;
    background: #f9670c;
    margin-top: 44px;
    margin-bottom: 40px;
    margin-left: 20px;
  }
}
</style>
