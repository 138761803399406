<template>
  <div>
    <!-- <renyiHeader :menu-id="1" /> -->
    <div class="introContent1">
      <div class="top1Box">
        <topTitle title="北京人民艺术剧院简介" />
      </div>
      <div class="contentBox">
        <div class="content">
          <!-- 北京人民艺术剧院是一个具有独特表演风格的中外闻名的专业话剧院，始建于1952年6月，首任院长为著名剧作家曹禺，现任院长冯远征。在长达70余年的艺术发展中，北京人民艺术剧院上演了古今中外不同风格的剧目300余部，以长期的艺术实践与美学理论的积累，在现实主义与民族化、体验与体现及再现戏剧美学的内涵上，达到了完美的融合，形成了鲜明的演剧风格，创建了著名的“北京人艺演剧学派”，培养出一代又一代使话剧舞台熠熠生辉的艺术家。 -->
          {{ overViewInfo.RYJJ.content1 }}
        </div>
        <introContainer>
          <div class="content2">
            <!-- 上个世纪五、六十年代，剧院以演出郭沫若、老舍、曹禺的剧作而闻名于世，其代表的剧目有《龙须沟》《虎符》《蔡文姬》《武则天》《茶馆》《骆驼祥子》《雷雨》《日出》《北京人》《关汉卿》以及外国剧目《悭吝人》《伊索》《智者千虑，必有一失》等。《茶馆》一剧成为中国话剧史上具有里程碑意义的作品。著名戏剧家焦菊隐先生曾担任剧院的总导演，诞生了以欧阳山尊、梅阡、夏淳、于是之、刁光覃、舒绣文、田冲、朱琳、童超、郑榕、蓝天野、董行佶等人为代表的一大批导表演艺术家。 -->
            {{ overViewInfo.RYJJ.content2 }}
          </div>
          <banner
            v-if="isShow1"
            :img-list="overViewInfo.RYJJ.image1.slice(0, 3)"
          />
          <div class="content2">
            <!-- 上个世纪八十年代之后，剧院上演剧目百余部，其中多部剧作演出达百场以上，多次获得文华奖、五个一工程奖等。主要剧目有《王昭君》《绝对信号》《小井胡同》《红白喜事》《狗儿爷涅槃》《天下第一楼》《李白》《鸟人》《第一次的亲密接触》等；上演的外国名剧主要有《贵妇还乡》《上帝的宠儿》《推销员之死》《洋麻将》《哗变》《屠夫》等。《绝对信号》作为中国第一部小剧场话剧，开创了中国实验话剧先河，北京人艺的舞台演出呈现多样化的发展趋势。濮存昕、梁冠华、杨立新、宋丹丹、冯远征、吴刚、何冰、徐帆、陈小艺、胡军、王斑等一大批活跃于当今舞台上的演员脱颖而出。 -->
            {{ overViewInfo.RYJJ.content3 }}
          </div>
          <banner
            v-if="isShow1"
            :img-list="overViewInfo.RYJJ.image1.slice(3, 7)"
          />
        </introContainer>
        <introContainer>
          <div class="content2">
            <!-- 进入新世纪，剧院又推出了《万家灯火》《北街南院》《赵氏孤儿》《我爱桃花》《全家福》《白鹿原》《北京人》《大将军寇流兰》《哈姆雷特》《知己》《窝头会馆》《喜剧的忧伤》《我们的荆轲》《家》《推销员之死》《甲子园》《公民》《牌坊》《司马迁》《万尼亚舅舅》《玩家》《玩偶之家》《名优之死》《古玩》《老式喜剧》《杜甫》《阳光下的葡萄干》等一系列引人瞩目的优秀剧作，打造了多部现象级戏剧作品。 -->
            {{ overViewInfo.RYJJ.content4 }}
          </div>
          <banner
            v-if="isShow2"
            :img-list="overViewInfo.RYJJ.image2.slice(0, 6)"
          />
          <div class="content2">
            <!-- 近年来，北京人艺持续聚力打造话剧精品力作。建党百年之际，《香山之夜》实现重大革命历史题材的创新表达；《正红旗下》《天下第一楼》《天之骄子》《海鸥》等一批兼具人艺风格与创新思路的新老剧目登上舞台；新排《日出》《雷雨》《原野》等创新剧目对经典作品作出了全新解读；小剧场呈现出蓬勃活力，《长椅》《晚安，妈妈》《长子》《足球俱乐部》等引发观众强烈共鸣，并孵化出《赵氏孤儿》《哈姆雷特》等一批令人耳目一新的佳作，“北京人艺演剧学派”在守正中持续创新，长足发展。 -->
            {{ overViewInfo.RYJJ.content5 }}
          </div>
          <banner
            v-if="isShow2"
            :img-list="overViewInfo.RYJJ.image2.slice(6, 8)"
          />
        </introContainer>
        <introContainer>
          <div class="content2">
            <!-- 2022年6月，以“人民的剧院、艺术的殿堂”为主题的北京人艺建院70年院庆系列活动取得了热烈反响。进入新一个十年，北京人艺以五座剧场并举的规模迎来全新的发展格局。立足全国文化中心建设，打造“演艺之都”，推进“大戏看北京”，聚焦创作演出、人才培养、剧场建设、公共文化服务等工作，实现剧院高质量发展。 -->
            {{ overViewInfo.RYJJ.content6 }}
          </div>
          <img
            style="
              width: 8.0533rem;
              height: 3.4267rem;
              margin: 0.7467rem 0 0.9867rem 0;
            "
            v-if="isShow3"
            :src="overViewInfo.RYJJ.image3[0].image"
            alt=""
          />
          <div class="content2">
            <!-- 北京人艺着力培养汇聚艺术人才，为剧院发展培根蓄能。2019年、2022年两次开办北京人艺“表演学员培训班”，承继剧院“团带班”的人才培养传统，依托剧院内外的艺术家师资力量，结合实际发展需求，开展专业教学训练和舞台实践，为剧院演员队伍注入新生力量，探索出具有北京人艺特色的演员培养新模式；举办话剧编剧人才研修班，聚拢更多优秀中青年作家；邀约莫言、熊召政等一批人艺荣誉编剧持续为剧院定向创作；推行剧本恳谈会制度，持续促进剧本创作活力。人艺精神与艺术水准薪火相传。 -->
            {{ overViewInfo.RYJJ.content7 }}
          </div>
          <banner v-if="isShow4" :img-list="overViewInfo.RYJJ.image4" />
        </introContainer>
        <introContainer>
          <div class="content2">
            <!-- 今天的北京人艺已建设成剧场众多、设备先进、功能齐全、机制完善的国际一流剧院。从建院之初史家胡同56号的一方小院，到王府井大街22号首都剧场这座屹立在市中心的话剧艺术圣殿，再到拔地而起的北京国际戏剧中心，新时代的北京人民艺术剧院已经成为拥有首都剧场、北京人艺实验剧场、曹禺剧场、人艺小剧场、菊隐剧场五个不同规模剧场，一家专业戏剧博物馆，一个集创作、制作为一体的北京人艺艺术创作中心的高品质话剧艺术生产集群。每年有近30万观众走进剧场观看人艺话剧。 -->
            {{ overViewInfo.RYJJ.content8 }}
          </div>
          <banner v-if="isShow5" :img-list="overViewInfo.RYJJ.image5" />
          <div class="content2">
            <!-- 北京人艺致力于讲好中国故事，以话剧为桥搭建国际艺术交流的平台，推动中外文化互鉴。自1980年《茶馆》代表中国话剧第一次走出国门，在德国、法国、瑞士三国巡演，40多年来，《天下第一楼》《李白》《知己》《我们的荆轲》等一大批优秀剧目多次赴欧洲、亚洲、北美洲、非洲演出，成功将带有鲜明中国气派、民族特色、人艺风格的话剧作品推向世界舞台。邀请国际著名导演来院导戏，以高水平的艺术质量打造国内外“精品剧目邀请展演”品牌。多元化的戏剧交流使北京人艺的艺术疆域更为广阔。 -->
            {{ overViewInfo.RYJJ.content9 }}
          </div>
          <div class="content2">
            <!-- 历史流转不停，舞台生生不息。陪伴中国话剧走过70余年的北京人民艺术剧院将继续坚持以“人民性”“民族性”“时代性”为中心的创作理念，坚持“戏比天大”的创作精神，秉持“打造世界一流的学者型剧院”的目标，以“龙马风神、骆驼坦步”的姿态踏上全新的征程，书写新时代话剧舞台的新辉煌。 -->
            {{ overViewInfo.RYJJ.content10 }}
          </div>
        </introContainer>
        <div class="orgBox">
          <div class="introTitle">
            <img src="../../../assets/img/renyiOverview/jiantou.png" />
            <span class="title">组织机构</span>
          </div>
          <div class="line"></div>
          <div class="title1">
            <img src="../../../assets/img/renyiOverview/title1.png" alt="" />
            <span>内设机构</span>
          </div>
          <div class="orgTreeBox">
            <vue2-org-tree
              :data="data1"
              :renderContent="renderContent1"
              @on-node-click="showLeader"
            />
          </div>
          <div class="leaderBox" v-if="isExpand">
            <div class="leaderList">
              <div
                class="leaderItem"
                v-for="(item, i) in leaderInfo"
                :key="i"
                :style="{ backgroundImage: 'url(' + item.image + ')' }"
              >
                <div class="l1">{{ item.job_position }}</div>
                <div class="l2">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div class="orgTreeBox" v-show="isExpand">
            <div class="shouqi" @click="isExpand = false">
              <img src="../../../assets/img/renyiOverview/shouqi.png" alt="" />
              <div class="shouqiText">收起</div>
            </div>
          </div>
          <div class="orgTreeBox">
            <vue2-org-tree
              :data="data2"
              :renderContent="renderContent2"
              @on-node-click="clickNode"
            />
          </div>
          <div class="title1" style="margin: 91px 0">
            <img src="../../../assets/img/renyiOverview/title1.png" alt="" />
            <span>所属机构</span>
          </div>
          <div class="org1">
            <div class="top">{{ overViewInfo.SSJG.organization1 }}</div>
            <div class="shuBox">
              <div class="shu"></div>
              <div class="dot"></div>
            </div>
            <div class="contBox">
              <div class="cont1">
                <div class="cont">
                  <div style="text-indent: 2em">
                    <!-- 北京明日经典文化发展有限公司系北京人民艺术剧院依法设立的独资企业，于2003年7月21日正式注册成立，注册地址为北京市东城区王府井大街22号，由北京人艺演出中心负责公司业务经营与管理。公司秉承“维护人艺品牌、创造自身价值”的宗旨，“服务观众、提升服务品质”的经营理念，不断传播人艺文化和戏剧艺术。 -->
                    {{ overViewInfo.SSJG.content1 }}
                  </div>
                  <div style="text-indent: 2em">
                    <!-- 目前，公司主要业务以戏剧书店及咖啡厅等剧场配套空间的日常运营、剧院主题文创衍生产品的开发为主，同时挖掘剧院区域可以展览、展示、互动、交流的开放性艺术空间，加强对外联盟合作，开发具有区域性特色的艺术联动项目，以“精致、唯美、高雅、志趣”为出发点，打造剧院艺术生态圈，吸引更多人群驻足剧场。 -->
                    {{ overViewInfo.SSJG.content2 }}
                  </div>
                </div>
              </div>
              <div class="line1"></div>
            </div>
          </div>
          <div class="org1" style="margin-top: 1.12rem">
            <div class="top">{{ overViewInfo.SSJG.organization2 }}</div>
            <div class="shuBox">
              <div class="shu"></div>
              <div class="dot"></div>
            </div>
            <div class="contBox">
              <div class="cont1">
                <div class="cont">
                  <div style="text-indent: 2em">
                    <!-- 北京人民艺术剧院发展基金会是经北京市民政局批准，于1993年6月10日成立的非公募基金会，业务主管单位为北京市文学艺术界联合会，现有11名理事，现任理事长为郭斌，副理事长为唐烨。 -->
                    {{ overViewInfo.SSJG.content3 }}
                  </div>
                  <div style="text-indent: 2em">
                    <!-- 基金会以开展慈善活动为宗旨，不以营利为目的，遵守宪法、法律、法规和国家政策，为剧院的发展和新剧目排演提供资金支持，为剧院青年演员的成长提供帮助。近年来，先后主办“喜迎二十大
                    奋进新征程——群众诗歌朗诵会”、承办“人艺之友日”、协办“‘京’彩文化
                    青春绽放”等活动。 -->
                    {{ overViewInfo.SSJG.content4 }}
                  </div>
                </div>
              </div>
              <div class="line1"></div>
            </div>
          </div>
        </div>
        <div class="jcjj">
          <topTitle title="剧场简介" />
          <div class="content2">
            <!-- 北京人民艺术剧院位于北京市东城区王府井大街22号，院内有落成于1955年的首都剧场和落成于2021年的北京国际戏剧中心两座剧场建筑。首都剧场内有“首都剧场”、“北京人艺实验剧场”两座剧场；北京国际戏剧中心内有“曹禺剧场”、“人艺小剧场”两个剧场；位于东四南大街143号的“菊隐剧场”是北京人艺的第五个剧场。 -->
            {{ overViewInfo.JCJJ.content1 }}
          </div>
          <banner
            v-if="isShow6"
            :img-list="overViewInfo.JCJJ.image1.slice(0, 2)"
          />
        </div>
        <introContainer>
          <div class="content2">
            <!-- 北京人艺的五个剧场定位清晰、特色鲜明，共同构成错落有致的专业话剧剧场集群。首都剧场作为北京人艺的经典剧场，主要为观众奉献经典剧目和传统剧目，以及国内外一流话剧作品；曹禺剧场着重创新、荟萃、交流定位，是人艺戏剧探索和创新，以及国内外高水平戏剧作品的交流平台；北京人艺实验剧场和人艺小剧场持续保持小剧场高地定位，发挥创作引领作用；菊隐剧场作为群众戏剧主阵地，则致力于打造戏剧普及的公益平台。五个剧场的新格局将助力北京人艺打造更多精品力作，推动国际国内戏剧艺术交流，开创更多元、更丰富、更高端的演艺新格局，形成与全国文化中心地位相匹配的全国话剧新高地。 -->
            {{ overViewInfo.JCJJ.content2 }}
          </div>
          <banner
            v-if="isShow6"
            :img-list="overViewInfo.JCJJ.image1.slice(2, 7)"
          />
        </introContainer>
        <div class="jcjj">
          <topTitle title="北京人民艺术剧院戏剧博物馆" />
          <div class="content2">
            <!-- 北京人民艺术剧院戏剧博物馆（简称北京人艺戏剧博物馆）位于北京东城区王府井大街22号首都剧场4层，于2007年6月12日正式对社会免费开放，开放时间为10:30—19:00，周一闭馆。 -->
            {{ overViewInfo.BWG.content1 }}
          </div>
          <div class="content2">
            <!-- 北京人艺戏剧博物馆是中国首家展示话剧艺术的专题博物馆，集馆藏、研究、展陈、教育功能于一体，共设序厅、历史厅、人物厅、剧目厅、舞美厅、综合厅等6个主题展厅以及独具匠心的艺术家走廊和演员墙。博物馆展示了话剧艺术创作手稿，也展示了服装、道具、舞台美术模型等实物，集中展示了北京人艺的建院史和艺术发展史，彰显剧院奠基人曹禺、焦菊隐、欧阳山尊、赵起扬等大师的生平情况和艺术成就，展现北京人艺薪火相传的几代艺术家塑造的众多艺术形象。北京人艺戏剧博物馆的馆藏文物极为丰富，具有独特的史料价值，诸如曹禺、老舍、焦菊隐、郭沫若、田汉、沈从文等一代戏剧文学大师的手书文稿、导演手记、往来信件等资料，记载着几代文学家、艺术家与北京人艺合作的过程。 -->
            {{ overViewInfo.BWG.content2 }}
          </div>
          <div class="content2">
            <!-- 为充分利用藏品资源，满足观众了解戏剧的需求，博物馆每年不定期举办主题展览、戏剧讲座、群众戏剧展演、戏剧体验等公益活动，并陆续推出各类戏剧理论研究专著，以增进与社会和观众的互动，为话剧的研究和交流提供一个更开放的空间。 -->
            {{ overViewInfo.BWG.content3 }}
          </div>
          <banner v-if="isShow7" :img-list="overViewInfo.BWG.image1" />
        </div>
        <div class="jcjj">
          <topTitle title="北京人民艺术剧院艺术创作中心" />
          <div class="content2">
            <!-- 北京人民艺术剧院艺术创作中心（简称北京人艺艺术创作中心）成立于2012年，坐落于北京市怀柔区杨宋镇影视科技文化园区，集艺术创作、舞美制作与存储为一体，是专门服务于北京人艺艺术生产，并具有一定规模和生产力的综合性艺术创作中心。 -->
            {{ overViewInfo.CZZX.content1 }}
          </div>
          <div class="content2">
            <!-- 北京人艺艺术创作中心延续早期人艺舞美工厂“最全面、最专业”的创作理念，设立了制图、美工、车间管理、库房管理、行政运维管理等部门，将制作生产流程有机地结合起来。现有制作车间4400平方米，包含焊接切割、木工、美工绘景间等多个功能分区。库房3000余平方米，分为生产材料、生产工具和布景库区等。办公区及宿舍区共计1500余平方米，厂区职工70余人。每年平均生产4到7部新排剧目的布景道具，同时能够承担6到14部复排重排剧目舞美道具的维修工作，库房可以储存25到40部大小剧目的布景道具。 -->
            {{ overViewInfo.CZZX.content2 }}
          </div>
          <div class="content2">
            <!-- 北京人艺艺术创作中心坚守人艺独特的舞台美学思想和艺术理念，保证了舞台演出的统一性和整体性，为剧目的舞台呈现与艺术表达提供了保障，为剧院艺术风格的发展贡献了力量。 -->
            {{ overViewInfo.CZZX.content3 }}
          </div>
          <banner v-if="isShow8" :img-list="overViewInfo.CZZX.image1" />
        </div>
        <div class="jcjj">
          <topTitle title="北京人艺戏剧书店" />
          <div class="content2">
            <!-- 北京人艺戏剧书店位于北京市东城区王府井大街22号首都剧场内，成立于2004年9月28日，营业时间为演出日14:00-演出结束，非演出日
            14:00-19:30，周一休息。 -->
            {{ overViewInfo.XJSD.content1 }}
          </div>
          <div class="content2">
            <!-- 北京人艺戏剧书店是人艺文化的一个展示窗口，也是人艺所属剧场文化设置的重要配套空间。书店坚守“以戏汇人，以书传艺”的理念，以传播戏剧文化和推广“人艺”话剧艺术为主线，体现艺术性、专业性、互动性的空间风格。在这个小小的阅读空间里，每一个细节，都与戏剧有关。这里也许找不到很多时下各种图书榜单流行的畅销书籍，但书店紧紧围绕“戏剧”主题，兼顾专业读者和普通爱好者的不同需求，引进戏剧和艺术相关书目，既保持着书籍专业性，也藉由戏剧艺术的经典与通俗读本，让更多读者爱上戏剧。 -->
            {{ overViewInfo.XJSD.content2 }}
          </div>
          <banner v-if="isShow9" :img-list="overViewInfo.XJSD.image1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "./components/banner.vue";
import introContainer from "./components/introContainer.vue";
import renyiHeader from "./components/renyiHeader.vue";
import topTitle from "./components/topTitle.vue";
import slotImg from "@/assets/img/slot2.png";
import zhankai from "@/assets/img/renyiOverview/zhankai.png";
import { getOrgTreeList } from "@/service/renyiOverview";
import { getDetail, getLeaderDetail } from "@/service/renyigailan";
export default {
  components: {
    renyiHeader,
    topTitle,
    introContainer,
    banner,
  },
  data() {
    return {
      data: {},
      data1: {},
      data2: {},
      imgList1: [],
      query: {
        level1: "org",
        level2: "",
      },
      isExpand: false,
      leaderInfo: [],
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      isShow6: false,
      isShow7: false,
      isShow8: false,
      isShow9: false,
      overViewInfo: {
        RYJJ: {
          content1: "",
          content2: "",
          content3: "",
          content4: "",
          content5: "",
          content6: "",
          content7: "",
          content8: "",
          content9: "",
          content10: "",
          image1: [],
          image2: [],
          image3: [],
          image4: [],
          image5: [],
        },
        JCJJ: {
          content1: "",
          content2: "",
          image1: [],
        },
        BWG: {
          content1: "",
          content2: "",
          content3: "",
          image1: [],
        },
        CZZX: {
          content1: "",
          content2: "",
          content3: "",
          image1: [],
        },
        XJSD: {
          content1: "",
          content2: "",
          image1: [],
        },
        SSJG: {
          organization1: "",
          organization2: "",
          content1: "",
          content2: "",
          content3: "",
          content4: "",
        },
      },
    };
  },
  mounted() {
    this.getOrgTree();
    this.getOverviewDeatail();
    this.getOverviewDeatailLeaderDeatail();
  },
  methods: {
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
    async getOverviewDeatail() {
      const res = await getDetail();
      if (res.data.RYJJ) this.overViewInfo.RYJJ = res.data.RYJJ;
      if (this.overViewInfo.RYJJ.image1.length) this.isShow1 = true;
      if (this.overViewInfo.RYJJ.image2.length) this.isShow2 = true;
      if (this.overViewInfo.RYJJ.image3.length) this.isShow3 = true;
      if (this.overViewInfo.RYJJ.image4.length) this.isShow4 = true;
      if (this.overViewInfo.RYJJ.image5.length) this.isShow5 = true;
      if (res.data.JCJJ) this.overViewInfo.JCJJ = res.data.JCJJ;
      if (this.overViewInfo.JCJJ.image1.length) this.isShow6 = true;
      if (res.data.BWG) this.overViewInfo.BWG = res.data.BWG;
      if (this.overViewInfo.BWG.image1.length) this.isShow7 = true;
      if (res.data.CZZX) this.overViewInfo.CZZX = res.data.CZZX;
      if (this.overViewInfo.CZZX.image1.length) this.isShow8 = true;
      if (res.data.XJSD) this.overViewInfo.XJSD = res.data.XJSD;
      if (this.overViewInfo.XJSD.image1.length) this.isShow9 = true;
      if (res.data.SSJG) this.overViewInfo.SSJG = res.data.SSJG;
    },
    async getOverviewDeatailLeaderDeatail() {
      const res = await getLeaderDetail();
      this.leaderInfo = res.data;
    },
    TreeDataSource(arr) {
      if (!Array.isArray(arr)) {
        return false;
      }
      return arr.map((v, i) => {
        return {
          label: v.name,
          value: v.id,
          ...v,
          children: v.children ? this.TreeDataSource(v.children) : [],
        };
      });
    },
    clickCItem(id) {
      if (id === 4 || id === 5) {
        this.$router.push({
          path: "/renyiOverView/capitalTheatre",
          query: {
            id,
          },
        });
      }
      if (id === 7 || id === 8) {
        this.$router.push({
          path: "/renyiOverView/theatreCenter",
          query: {
            id,
          },
        });
      }
    },
    async getOrgTree() {
      const res = await getOrgTreeList();
      this.data1 =
        JSON.parse(JSON.stringify(this.TreeDataSource(res.data)[0])) || {};
      this.data1.children[0].children = [];
      const org2 =
        JSON.parse(JSON.stringify(this.TreeDataSource(res.data)[0])) || {};
      const child = org2.children[0].children;
      this.data2 = {
        label: "",
        value: null,
        children: child,
      };
      // this.orgInfoTitle = this.data.name;
      // this.query.level2 = this.data.id;
      // getArticle(this.query).then((res) => {
      //   this.orgInfo = res.data;
      //   this.orgInfo.body = formatRichText(this.orgInfo.body);
      // });
    },
    renderContent1(h, data) {
      return (
        <div class="t1">
          {data.label}
          {data.label === "院领导" ? <img src={zhankai} /> : ""}
        </div>
      );
    },
    renderContent2(h, data) {
      if (data.children.length) {
        return <div class="t4">{data.label}</div>;
      } else {
        return <div class="t2">{data.label}</div>;
      }
    },
    showLeader(e, value) {
      // if (value.label === "院领导") {
      //   this.isExpand = !this.isExpand;
      // }
    },
    clickNode(e, value) {
      const nodeList = document.querySelectorAll(".t2");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].classList.remove("t3");
      }
      if (value.children.length == 0) {
        e.target.classList.add("t3");
      }
      // this.orgInfoTitle = value.name;
      // this.query.level2 = value.id;
      // getArticle(this.query).then((res) => {
      //   this.orgInfo = res.data;
      // });
    },
  },
};
</script>

<style lang="less">
.introContent1 {
  box-sizing: border-box;
  .top1Box {
    width: 100%;
    height: 4.5333rem;
    background: url("../../../assets/img/renyioverview_mobile/top1/bg.png")
      no-repeat;
    background-size: cover;
    padding: 1.84rem 0.5333rem;
    box-sizing: border-box;
  }
  .contentBox {
    width: 100%;
    padding: 0 0.5333rem;
    margin-top: 1.24rem;
    box-sizing: border-box;
    .content {
      width: 8.92rem;
      font-size: 0.4rem;
      color: #212121;
      line-height: 0.8rem;
      letter-spacing: 0.01rem;
      text-indent: 2em;
      text-align: justify;
    }
    .content2 {
      width: 8.0533rem;
      font-size: 0.3733rem;
      text-align: justify;
      color: #333333;
      line-height: 0.6933rem;
      letter-spacing: 0.0093rem;
      text-indent: 2em;
    }
    .jcjj {
      padding: 0 0.44rem;
      margin-top: 1.2rem;
      box-sizing: border-box;
    }
  }
  .orgBox {
    margin-top: 2.0133rem;
    // padding: 0 0 39px 77px;
    // box-sizing: border-box;
    .introTitle {
      display: flex;
      align-items: center;
      img {
        width: 0.2667rem;
        height: 0.5333rem;
        margin-right: 0.1867rem;
      }
      .title {
        font-size: 0.4rem;
        font-weight: 700;
        color: #212122;
        letter-spacing: 0.01rem;
      }
    }
    .line {
      width: 0.3467rem;
      height: 0.0533rem;
      background: #f9670c;
      margin-top: 0.5867rem;
      margin-bottom: 0.5333rem;
      margin-left: 0.2667rem;
    }
    .title1 {
      display: flex;
      align-items: center;
      margin-left: 0.2667rem;
      margin-bottom: 0.5467rem;
      img {
        width: 0.2533rem;
        height: 0.2533rem;
        margin-right: 0.24rem;
      }
      span {
        font-size: 0.3733rem;
        font-weight: 700;
        color: #212121;
      }
    }
    .orgTreeBox {
      display: flex;
      justify-content: center;
      width: 100%;
      .shouqi {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        img {
          width: 0.2rem;
          height: 0.2rem;
          object-fit: cover;
          margin-bottom: 0.12rem;
        }
        .shouqiText {
          font-size: 0.1867rem;
          color: #f9680d;
        }
      }
    }
    .leaderBox {
      .leaderList {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0.5333rem;
        .leaderItem {
          width: 2.8533rem;
          height: 3.9733rem;
          background-size: cover !important;
          background: no-repeat;
          margin-bottom: 0.5333rem;
          .l1 {
            font-size: 0.24rem;
            font-weight: 700;
            text-align: center;
            color: #222222;
            margin-top: 3.2267rem;
            margin-bottom: 0.12rem;
          }
          .l2 {
            font-size: 0.2rem;
            font-weight: 400;
            text-align: center;
            color: #555555;
          }
        }
      }
    }
    .org1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .top {
        width: 5.6rem;
        height: 1.08rem;
        background: url("../../../assets/img/renyiOverview/node_title.png")
          no-repeat;
        background-size: cover;
        line-height: 1.08rem;
        text-align: center;
        font-size: 0.3467rem;
        font-weight: 500;
        color: #ffffff;
      }
      .shuBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .shu {
          width: 0.0133rem;
          height: 1.2667rem;
          opacity: 0.5;
          background: #f9680d;
        }
        .dot {
          width: 0.5333rem;
          height: 0.0267rem;
          background: #f9670c;
        }
      }
      .contBox {
        // width: 12.8533rem;
        // height: 3.4667rem;
        // position: relative;
        .cont1 {
          width: 8.9333rem;
          // height: 3.4667rem;
          // border: 0.0133rem solid #e1e1e1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          background: #f7f7fa;
          padding: 0.64rem 0.4533rem;
          box-sizing: border-box;
          .cont {
            width: 8.0267rem;
            font-size: 0.3733rem;
            color: #555555;
            line-height: 0.6933rem;
            letter-spacing: 0.0093rem;
          }
        }
        // .line1 {
        //   position: absolute;
        //   bottom: 0;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   width: 0.6667rem;
        //   height: 0.0267rem;
        //   background: #f9680d;
        // }
      }
    }
  }
  .t1 {
    width: 5.6rem;
    height: 1.08rem;
    line-height: 1.08rem;
    color: #fff;
    font-size: 0.3467rem;
    // background-color: red;
    background: url("../../../assets/img/renyiOverview/node_title.png")
      no-repeat;
    background-size: 100%;
    text-align: center;
    // cursor: pointer;
    img {
      width: 0.2133rem;
      height: 0.2133rem;
      margin-left: 0.1333rem;
      cursor: pointer;
    }
  }
  .t2 {
    display: flex;
    align-items: center;
    justify-content: center;
    writing-mode: vertical-lr;
    width: 0.6667rem;
    height: 2.5333rem;
    font-size: 0.2667rem;
    background: url("../../../assets/img/renyiOverview/node.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
  }

  .t2:hover {
    background: url("../../../assets/img/renyiOverview/node_active.png")
      no-repeat;
    background-size: 100%;
  }

  .t3 {
    display: flex;
    align-items: center;
    justify-content: center;
    writing-mode: vertical-lr;
    width: 0.7333rem;
    height: 1.9333rem;
    font-size: 0.2667rem;
    background: url("../../../assets/img/renyiOverview/node_active.png")
      no-repeat;
    background-size: 100%;
  }
  .t4 {
    width: 0;
    height: 0;
  }
  .org-tree-node-label .org-tree-node-label-inner {
    padding: 0;
  }
  .org-tree-container {
    padding: 15/75rem 0;
  }
  .org-tree-node.collapsed, .org-tree-node.is-leaf {
    padding: 0 1/75rem;
  }
}
</style>
